import { PROXY_URL } from "@/config";
import sdk from "@/libs/spheron-sdk";
import { LeaseState, SpheronSDK } from "@spheron/protocol-sdk";

export const getLeaseDetails = async (leaseId: string) => {
  console.log("Lease ID ->", leaseId);
  return sdk.leases.getLeaseDetails(leaseId);
};

export const getTenantLeases = async (tenantAddress: string) => {
  return sdk.leases.getLeaseIds(tenantAddress);
};

export const getTenantLeasesByState = async (
  tenantAddress: string,
  options: { state?: LeaseState; page: number; pageSize: number }
) => {
  return sdk.leases.getLeasesByState(tenantAddress, {
    state: options.state,
    page: options.page,
    pageSize: options.pageSize,
  });
};

export const getLeaseDetailsProvider = async (
  providerUrl: string,
  leaseId: string,
  authToken: string
) => {
  const SDK = new SpheronSDK(`https://${providerUrl}`, PROXY_URL);
  try {
    return await SDK.spheronProvider.getLeaseStatus("", authToken, leaseId);
  } catch (error) {
    throw error;
  }
};

export const getLeaseLogs = async (
  providerUrl: string,
  leaseId: string,
  authToken: string,
  serviceName: string = "",
  startup: boolean = false
) => {
  const SDK = new SpheronSDK(`https://${providerUrl}`, `${PROXY_URL}/ws-data`);
  return SDK.spheronProvider.getLeaseLogs(
    "",
    authToken,
    leaseId,
    serviceName,
    10000,
    startup
  );
};

export const getLeaseEvents = async (
  providerUrl: string,
  leaseId: string,
  authToken: string
) => {
  const SDK = new SpheronSDK(`https://${providerUrl}`, `${PROXY_URL}/ws-data`);
  return SDK.spheronProvider.getKubeEvents("", authToken, leaseId);
};

export const getLeaseServiceDetails = async (
  providerUrl: string,
  leaseId: string,
  authToken: string,
  serviceName: string
) => {
  const SDK = new SpheronSDK(`https://${providerUrl}`, PROXY_URL);
  return SDK.spheronProvider.getLeaseServiceStatus(
    "",
    authToken,
    leaseId,
    serviceName
  );
};

export const closeLease = async (leaseId: string) => {
  try {
    const SDK = new SpheronSDK();
    await SDK.leases.closeLease(leaseId);
    return;
  } catch (error) {
    console.log("ERROR closing lease", error);
    throw error;
  }
};
