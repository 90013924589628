import { BASE_URL, SERVICE_API_URL } from "@/config";
import { mapTokenToId } from "@/utils";

export const getLiveTokenPrice = async (token: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/price/liveTokenPrice/${mapTokenToId(token)}`,
      { method: "GET", cache: "no-cache" }
    );
    if (!response.ok)
      throw new Error(`Error in getLiveTokenPrice status: ${response.status}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error in getLiveTokenPrice for token: ${token}`, error);
  }
};

export const getLiveTokenPriceSSR = async (token: string) => {
  try {
    const response = await fetch(
      `${SERVICE_API_URL}/v1/price/liveTokenPrice/${mapTokenToId(token)}`,
      { method: "GET", cache: "no-cache" }
    );
    if (!response.ok)
      throw new Error(`Error in serviceApi getLiveTokenPrice status: ${response.status}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error in serviceApi getLiveTokenPrice for token: ${token}`, error);
  }
};
