import * as amplitude from "@amplitude/analytics-browser";
import { getCookie } from ".";

export const trackUserEvent = (
  event: string,
  properties?: Record<string, any>
) => {
  const email = getCookie("email");
  amplitude.track(event, { email, ...properties });
};
