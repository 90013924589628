// // Old addresses
// export const TokenRegistry = "0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe";
// export const ProviderRegistry = "0xc51F366131924e477a65b05D9ca97333daa3385D";
// export const Escrow = "0x495d613910FdeB29E83d9fb4B25EbFC3ACc8ddc4";
// export const OrderRequest = "0xB4df88D433Ff0AFe0DF3e2aE39681C28D8F71937";
// export const ResourceManager = "0x217Bf67daee35A2e59334B5209069FED1F10883A";
// export const ComputeLease = "0x5b6bf1923F11E3a6A123ed162E11fbBe39F51161";
// export const Bid = "0xb32026402F12aAE59Ee37E60495A434C1a044E27";
// export const ResourceRegistryCPU = "0xd92F72Ffc27E5949F61d10012760B2A98b508c02";
// export const ResourceRegistryGPU = "0xe479b5DC6D59B37f62b428Aeb84dF84C33Ce356A";
// export const ResourceRegistryFactory = "0xcD488162aB95759c83eEaC26660c2146Aa7B0B6f";
// export const RewardManager = "0x90e83Bf679DdAF16d4C1a1AF38BeD29293BBfCDf";
// export const RewardToken = "0xA0c5ba7d9eccbE59000e8E67654CA5d867C6b401";

// // Token addresses
// export const USDT = "0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0";
// export const USDC = "0x809372ebc19017276f1b5AF5678c306513567118";
// export const DAI = "0x014E547FdD3a7625c6BEb5Af92F34F0960D29ad3";
// export const WETH = "0x55B1835d60Bb7CFcfB838E0963E58ad84Fd9694F";

// New Testnet Contract Addresses
export const TokenRegistry = "0x30E76950e5eFf7f193FD0a55d86E5249f5f51F7B";
export const ProviderRegistry = "0x1C64658c0738eB15744DDCFE0Efe442994AA4cd5";
export const ProviderAttributeRegistry =
  "0x4a356b572522eD2EF88D04A30C53AEfBdF854A88";
export const FizzRegistry = "0x14ae53c7130746d5342c53216Fc7622D30F236c0";
export const FizzAttributeRegistry =
  "0x8B4e0fE5C9293855681e518fb9B1c8B0114A0f83";
export const Escrow = "0x1377BF6D421dc2926b54a93A4bBDDb9F8E857EFc";
export const OrderRequest = "0x66286846010EE3A43D85175b04D5BA5A176Cb961";
export const ResourceManager = "0xF01fB027593DD2C60e0024AF8F9014c41fA0756e";
export const ComputeLease = "0x314C4616C6371686234DeE6244F40a4E199c7E87";
export const Bid = "0x4AF4902D5f0F5c83a40A41f561273f884854e087";
export const ResourceRegistryCPU = "0x7aEEE296044c955A707A3A0BB6E1A505bbD15585";
export const ResourceRegistryGPU = "0x01e909Ac5E5D833998bA84a1BB2a16d227A9b3ea";
export const ResourceRegistryFactory =
  "0xd6d4C265B3549c4425DF1a15CCEA0634EcB351D2";
export const RewardManager = "0x1b932690a36966C56e89103b786E0bb01bDDDc36";
export const RewardToken = "0xa8ACde36Ec65A5ca286CE5617f0b22F26341B8aE";

// New Testnet Token addresses
export const USDT = "0x01e65Ad8F4824037Ed8b3111A6c232D1b8458Bea";
export const USDC = "0xbb132eB1b36D87ef7Ac63c95c05a8a56eCDac3fd";
export const DAI = "0x1fFC5C93E3037aa387D3A988e9aCF2Cd11C8B782";
export const WETH = "0xcB66efD46915F97Dd97951a98941ca8d02642aaa";
export const CST = "0xA76CF27b51eb93c417CcE78af5cf0a3E2D9aa55c";
